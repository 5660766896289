
import './App.css';

function Logo() {
  return (
    <div className="Logo">
      <header className="App-header">
        <div className="hub">
          <span >Pe</span>
          <span >ter</span>
          <a href="https://www.linkedin.com/in/peterleung2/" target="_blank" rel="noreferrer" className="link">
            Linkedin
          </a>

          <a href="./Peter_Leung_Resume.pdf" target="_blank" rel="noreferrer" className="link">
            Resume
          </a>
          
        </div>
        
      </header>
    </div>
  );
}

export default Logo;
