import './pic.css';

function Pic(){
    return(
        <div classNAme='parent'>
            <img src="./pic1.jpg" className="img" alt=""/>
            <br></br>
            <img src="./jul4.jpg" className="img" alt=""/>
        </div>
    );
}

export default Pic